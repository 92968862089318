import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ImprintComponent } from './components/imprint/imprint.component';
import { CommingSoonComponent } from './components/comming-soon/comming-soon.component';
import { PirvacyPolicyComponent } from './components/pirvacy-policy/pirvacy-policy.component';


const routes: Routes = [
  { path: '', component: CommingSoonComponent },
  { path: 'imprint', component: ImprintComponent },
  { path: 'privacyPolicy', component: PirvacyPolicyComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
