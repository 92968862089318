import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommingSoonComponent } from './components/comming-soon/comming-soon.component';
import { ImprintComponent } from './components/imprint/imprint.component';
import { PirvacyPolicyComponent } from './components/pirvacy-policy/pirvacy-policy.component';

@NgModule({
  declarations: [
    AppComponent,
    CommingSoonComponent,
    ImprintComponent,
    PirvacyPolicyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
